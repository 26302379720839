import React, { useState, useEffect, useRef, Fragment } from 'react'
import { connect } from 'react-redux'
import {
  IFText,
  IFFilter,
  IFDialog,
  IFSkeleton,
  IFsvg,
  IFAvatar,
  IFScrollbars,
  IFTooltipIconsLoading,
  IFToastMessage,
} from 'Components'
import {
  UserTransactionList,
  SubscriptionContainer,
  AccessGroupsContainer,
} from 'Containers'
import { Colors } from 'Theme'
import Styles from './UserDetails.module.css'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import RequestState from 'Enums/RequestState'
import Icon from '@material-ui/core/Icon'
import DateRangeIcon from '@mui/icons-material/DateRange'
import moment from 'moment'
import styled from 'styled-components'
import RefreshIcon from '@mui/icons-material/Refresh'
import UserSelectors from 'Stores/User/Selectors'
import UserActions from 'Stores/User/Actions'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { toaster } from 'rsuite'
import AuthSelectors from 'Stores/Auth/Selectors'
import UserSessions from 'Containers/UserSessions/UserSessions'
import UserSpecsContainer from 'Containers/UserSpecsContainer/UserSpecsContainer'
import InfinityEnums from 'Enums/InfinityEnums'

const LeftContainer = styled.div`
  border-right: ${(props) => (props.underTenant ? '1px' : '0px')} solid
    ${Colors.DividerColor};
  padding-right: ${(props) => (props.underTenant ? '1rem' : '0rem')};
`

const UserGroupsContainer = styled.div`
  box-shadow: 0px 0px 4px 0px ${Colors.UserGroupsContainer};
  border-radius: 5px;
  padding-top: 0.5rem;
  padding-left: 1rem;
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
`

const UserDetails = ({
  selectedUser,
  selectedIndex,
  fetchUserListRequestState,
  fetchUserDetailsRequestState,
  userTransactionsFilter,
  users,
  fetchUser,
  clearUserTransactions,
  resetUserState,
  setUserTransactionsFilter,
  setUserTransactionsPaginationOffset,
  fetchUserTransactions,
  fetchUserTransactionsRequestState,
  deleteUser,
  deleteUserRequestState,
  deleteUserAccessGroupsRequestState,
  addUserAccessGroupsRequestState,
  addUserSubscriptionsRequestState,
  deleteUserSubscriptionsRequestState,
  userTransactions,
  tenantUser,
  fetchUserSessionListRequestState,
  userSessions,
}) => {
  const { t } = useTranslation()

  const confirmDeleteDialog = useRef()
  const subscriptionsRef = useRef()
  const accessGroupsRef = useRef()

  const refDiv = useRef()

  const [isEdit, setIsEdit] = useState(false)
  const [ascendingTransactions, setAscendingTransactions] = useState(false)
  const [height, setHeight] = useState(0)
  const [refreshingTransactions, setRefreshingTransactions] = useState(false)
  const [transactionListLastUpdated, setTransactionListLastUpdated] =
    useState(null)

  const checkFilterEmpty = (newFilter) => {
    const emptyFlag = !newFilter.some((filter) => filter.value.length !== 0)
    if (emptyFlag) return true
    else return false
  }

  useEffect(() => {
    if (
      (deleteUserAccessGroupsRequestState === RequestState.SUCCEEDED ||
        addUserAccessGroupsRequestState === RequestState.SUCCEEDED ||
        addUserSubscriptionsRequestState === RequestState.SUCCEEDED ||
        deleteUserSubscriptionsRequestState === RequestState.SUCCEEDED) &&
      deleteUserAccessGroupsRequestState !== RequestState.LOADING &&
      addUserAccessGroupsRequestState !== RequestState.LOADING &&
      addUserSubscriptionsRequestState !== RequestState.LOADING &&
      deleteUserSubscriptionsRequestState !== RequestState.LOADING
    ) {
      setIsEdit(false)
      toaster.push(
        <IFToastMessage type="success" text={t('UsersPage.UserUpdated')} />,
        {
          placement: 'topEnd',
        },
      )
    }
  }, [
    deleteUserAccessGroupsRequestState,
    addUserAccessGroupsRequestState,
    addUserSubscriptionsRequestState,
    deleteUserSubscriptionsRequestState,
  ])

  useEffect(() => {
    if (users.length !== 0) {
      setIsEdit(false)
      if (!selectedUser?.isInvitedUser) fetchUser(users[selectedIndex]._id)
      clearUserTransactions()
      resetUserState()
      setTransactionListLastUpdated(null)
    }
  }, [selectedUser?._id])

  useEffect(() => {
    if (selectedUser && Object.keys(selectedUser).length) {
      setUserTransactionsPaginationOffset(0)
      fetchUserTransactions(
        userTransactionsFilter,
        0,
        selectedUser._id,
        ascendingTransactions,
      )
    }
  }, [userTransactionsFilter])

  useEffect(() => {
    if (
      fetchUserTransactionsRequestState !== RequestState.LOADING &&
      fetchUserTransactionsRequestState !== RequestState.UNINITIALIZED
    ) {
      setRefreshingTransactions(false)
      setTransactionListLastUpdated(Date.now())
    }
  }, [fetchUserTransactionsRequestState])

  useEffect(() => {
    // max list height container height - padding,title,add button
    if (refDiv && refDiv.current) {
      setHeight(refDiv.current.getBoundingClientRect().height - 130)
    }
  }, [refDiv, selectedUser, isEdit])

  useEffect(() => {
    if (deleteUserRequestState === RequestState.SUCCEEDED) {
      if (confirmDeleteDialog.current) confirmDeleteDialog.current.dismiss()
      resetUserState()
      setIsEdit(false)
    }
  }, [deleteUserRequestState])

  const handleCancel = () => {
    setIsEdit(false)
    subscriptionsRef?.current?.handleCancel()
    accessGroupsRef?.current?.handleCancel()
  }

  const handleSubmit = () => {
    subscriptionsRef?.current?.handleSubmit(false)
    accessGroupsRef?.current?.handleSubmit(false)
  }

  const handleUserTransactions = (newFilter) => {
    clearUserTransactions()
    setUserTransactionsFilter(newFilter)
  }

  return (
    <Fragment>
      <div className={Styles.Container}>
        <LeftContainer
          className={Styles.LeftContainer}
          underTenant={
            fetchUserListRequestState === RequestState.LOADING ||
            (users.length > 0 && users[selectedIndex].email)
              ? true
              : false
          }
        >
          <div className={Styles.UserDetailsContainer}>
            <div className={Styles.UserCredentialsWrapper}>
              {fetchUserListRequestState === RequestState.LOADING ||
              fetchUserDetailsRequestState === RequestState.LOADING ? (
                <IFSkeleton variant="circular" width="4.5rem" height="4.5rem" />
              ) : (
                <div className={Styles.UserAvatarContainer}>
                  <IFAvatar
                    name={selectedUser?.name ? selectedUser.name : ''}
                    size={64}
                  />
                  {selectedUser?.appUserId && (
                    <div
                      className={Styles.StatusContainer}
                      style={{
                        backgroundColor: selectedUser?.isActive
                          ? Colors.UserActive
                          : Colors.UserBlocked,
                        border: `2.5px solid ${Colors.white} `,
                      }}
                    />
                  )}
                </div>
              )}

              <div className={Styles.UserCredentialsContainer}>
                <div
                  className={Styles.NameContainer}
                  style={{ color: Colors.text }}
                >
                  {fetchUserListRequestState === RequestState.LOADING ||
                  fetchUserDetailsRequestState === RequestState.LOADING ? (
                    <IFSkeleton variant="text" width="8rem" height="1.75rem" />
                  ) : (
                    <IFText className={Styles.NameText}>
                      {selectedUser?.name}
                    </IFText>
                  )}
                </div>
                {selectedUser?.email && (
                  <div className={Styles.EmailContainer}>
                    {fetchUserListRequestState === RequestState.LOADING ||
                    fetchUserDetailsRequestState === RequestState.LOADING ? (
                      <IFSkeleton
                        variant="text"
                        width="10rem"
                        height="1.5rem"
                      />
                    ) : (
                      <div className={Styles.EmailWrapper}>
                        <IFsvg.Email
                          height={18}
                          width={18}
                          fill={Colors.black}
                        />
                        <IFText
                          style={{ color: Colors.text }}
                          className={Styles.EmailText}
                        >
                          {selectedUser?.email}
                        </IFText>
                      </div>
                    )}
                  </div>
                )}
                {(selectedUser?.appUserId ||
                  fetchUserListRequestState === RequestState.LOADING ||
                  fetchUserDetailsRequestState === RequestState.LOADING) && (
                  <div className={Styles.EmailContainer}>
                    {fetchUserListRequestState === RequestState.LOADING ||
                    fetchUserDetailsRequestState === RequestState.LOADING ? (
                      <IFSkeleton
                        variant="text"
                        width="10rem"
                        height="1.5rem"
                      />
                    ) : (
                      <div className={Styles.EmailWrapper}>
                        <IFsvg.Tag height={18} width={18} fill={Colors.black} />
                        <IFText
                          style={{ color: Colors.text }}
                          className={Styles.EmailText}
                        >
                          {selectedUser?.appUserId}
                        </IFText>
                      </div>
                    )}
                  </div>
                )}
                <div className={Styles.StatusTextContainer}>
                  {fetchUserListRequestState === RequestState.LOADING ||
                  fetchUserDetailsRequestState === RequestState.LOADING ? (
                    <IFSkeleton variant="text" width="4rem" height="1.25rem" />
                  ) : (
                    <div className={Styles.EmailWrapper}>
                      {selectedUser?.isInvitedUser ? (
                        <IFText
                          style={{ color: Colors.primary }}
                          className={Styles.StatusText}
                        >
                          {t('UsersPage.Pending')}
                        </IFText>
                      ) : (
                        <IFText
                          style={{ color: Colors.text }}
                          className={Styles.StatusText}
                        >
                          {selectedUser?.isActive
                            ? t('UsersPage.Active')
                            : t('UsersPage.Blocked')}
                        </IFText>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {fetchUserListRequestState === RequestState.LOADING ||
            (users.length > 0 && users[selectedIndex].email) ? (
              <div style={{ color: Colors.black }}>
                {fetchUserListRequestState === RequestState.LOADING ||
                fetchUserDetailsRequestState === RequestState.LOADING ? (
                  <IFSkeleton variant="text" width="8rem" height=" 1.5rem" />
                ) : (
                  <div className={Styles.DateContainer}>
                    <Icon
                      sx={{ fontSize: '16px !important' }}
                      component={DateRangeIcon}
                    />
                    <IFText>
                      &nbsp;
                      {selectedUser
                        ? moment(selectedUser.invitationDate).format(
                            'MMM DD, YYYY HH:mm',
                          )
                        : ''}
                    </IFText>
                  </div>
                )}
              </div>
            ) : null}
          </div>
          <IFScrollbars>
            <div
              className={
                isEdit
                  ? Styles.SpecsSessionsContainerEdit
                  : Styles.SpecsSessionsContainer
              }
            >
              {!isEdit && (
                <div className={Styles.UserSessionsContainer}>
                  {fetchUserListRequestState === RequestState.LOADING ||
                  fetchUserDetailsRequestState === RequestState.LOADING ||
                  fetchUserSessionListRequestState === RequestState.LOADING ? (
                    <IFSkeleton
                      className={Styles.OngoingSessions}
                      width="7rem"
                      height="2rem"
                    />
                  ) : (
                    <IFText
                      className={Styles.OngoingSessions}
                      style={{ color: Colors.text }}
                    >
                      {t('UsersPage.OngoingSessions')}
                    </IFText>
                  )}

                  <div
                    className={
                      users.length > 0 && users[selectedIndex]?.email
                        ? Styles.UserSessions
                        : userSessions.length === 0
                        ? Styles.UserSessionsEmpty
                        : userSessions.length >= 3
                        ? Styles.UserSessions
                        : {}
                    }
                  >
                    <IFScrollbars>
                      <UserSessions />
                    </IFScrollbars>
                  </div>
                </div>
              )}

              {fetchUserListRequestState === RequestState.LOADING ||
              (users.length > 0 && users[selectedIndex]?.email) ? (
                <div
                  ref={refDiv}
                  className={
                    isEdit
                      ? Styles.UserGroupsContainerEdit
                      : Styles.UserGroupsContainer
                  }
                >
                  {!isEdit ? (
                    <div className={Styles.SubscriptionsContainer}>
                      <UserSpecsContainer isEditing={isEdit} />
                    </div>
                  ) : (
                    <div className={Styles.UserSpecificationsContainer}>
                      <div className={Styles.SubscriptionsContainerSeparate}>
                        {fetchUserListRequestState === RequestState.LOADING ||
                        fetchUserDetailsRequestState === RequestState.LOADING ||
                        selectedUser?.isInvitedUser ? (
                          <IFSkeleton
                            variant="text"
                            width="6rem"
                            height="1.75rem"
                          />
                        ) : (
                          <IFText
                            className={Styles.UsersGroupsText}
                            style={{ color: Colors.text }}
                          >
                            {t('UsersPage.Subscriptions')}
                          </IFText>
                        )}
                        <UserGroupsContainer
                          style={
                            isEdit
                              ? { marginLeft: '4px' }
                              : { flexGrow: 1, height: '10.25rem' }
                          }
                        >
                          <SubscriptionContainer
                            ref={subscriptionsRef}
                            isEditing={isEdit}
                            renderFor={
                              InfinityEnums.ChargePointSpecsContainer.USER
                            }
                            maxListHeight={height}
                            scrollbarsHeight={100}
                            removeExtraMargin={true}
                          />
                        </UserGroupsContainer>
                      </div>
                      <div className={Styles.AccessGroupsContainer}>
                        {fetchUserListRequestState === RequestState.LOADING ||
                        fetchUserDetailsRequestState === RequestState.LOADING ||
                        selectedUser?.isInvitedUser ? (
                          <IFSkeleton
                            variant="text"
                            width="6rem"
                            height="1.75rem"
                          />
                        ) : (
                          <IFText
                            className={Styles.UsersGroupsText}
                            style={{ color: Colors.text }}
                          >
                            {t('UsersPage.AccessGroups')}
                          </IFText>
                        )}

                        <UserGroupsContainer
                          style={
                            isEdit
                              ? { paddingRight: '1rem', marginRight: '4px' }
                              : { flexGrow: 1, height: '10.25rem' }
                          }
                        >
                          <AccessGroupsContainer
                            ref={accessGroupsRef}
                            isEditing={isEdit}
                            renderFor={
                              InfinityEnums.ChargePointSpecsContainer.USER
                            }
                            maxListHeight={height}
                            scrollbarsHeight={100}
                          />
                        </UserGroupsContainer>
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
            {!isEdit && (
              <div
                className={Styles.TransactionsContainer}
                style={{ marginTop: selectedUser?.email ? 0 : 16 }}
              >
                <div className={Styles.TransactionsTitlesContainer}>
                  {fetchUserListRequestState === RequestState.LOADING ||
                  fetchUserDetailsRequestState === RequestState.LOADING ||
                  fetchUserTransactionsRequestState === RequestState.LOADING ||
                  (selectedUser?.isInvitedUser && !selectedUser?.appUserId) ? (
                    <div className={Styles.TransactionLogContainer}>
                      <IFSkeleton
                        variant="text"
                        width="7rem"
                        height="1.75rem"
                      />
                    </div>
                  ) : (
                    <div className={Styles.TransactionLogContainer}>
                      <IFText
                        className={Styles.TransactionsLogText}
                        style={{ color: Colors.text }}
                      >
                        {t('UsersPage.TransactionLog')}
                      </IFText>
                    </div>
                  )}
                  {fetchUserListRequestState === RequestState.LOADING ||
                  fetchUserDetailsRequestState === RequestState.LOADING ||
                  fetchUserTransactionsRequestState === RequestState.LOADING ||
                  (selectedUser?.isInvitedUser && !selectedUser?.appUserId) ? (
                    <div className={Styles.RefreshIconTextContainer}>
                      <div>
                        <IFSkeleton
                          height="1.5rem"
                          className={Styles.RefreshTextSkeleton}
                        />
                        <IFSkeleton
                          height="1.25rem"
                          className={Styles.RefreshTextDateSkeleton}
                        />
                      </div>
                      <div
                        className={Styles.RefreshIcon}
                        style={{ marginBottom: '0.5rem' }}
                      >
                        <IFSkeleton
                          width="1.5rem"
                          height="1.5rem"
                          variant="circular"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className={Styles.RefreshIconTextContainer}>
                      <div className={Styles.RefreshTextContainer}>
                        <IFText
                          className={Styles.RefreshText}
                          style={{ color: Colors.black }}
                        >
                          {t('ChargePointDetails.lastUpdated')}
                        </IFText>
                        <IFText className={Styles.RefreshTextDate}>
                          {transactionListLastUpdated
                            ? moment(transactionListLastUpdated).format(
                                'MMM DD, YYYY HH:mm:ss',
                              )
                            : ''}
                        </IFText>
                      </div>
                      <div
                        className={Styles.RefreshIcon}
                        onClick={() => {
                          if (refreshingTransactions) return
                          setRefreshingTransactions(true)
                          setUserTransactionsPaginationOffset(0)
                          fetchUserTransactions(
                            userTransactionsFilter,
                            0,
                            selectedUser._id,
                            ascendingTransactions,
                          )
                        }}
                      >
                        <RefreshIcon
                          sx={{ height: '20px' }}
                          className={
                            refreshingTransactions ? Styles.refreshStart : null
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className={Styles.FilterContainer}>
                  {!(
                    userTransactions.length === 0 &&
                    checkFilterEmpty(userTransactionsFilter)
                  ) && (
                    <IFFilter
                      key={'IFFilter_UsersPage_Transactions'}
                      onFilterChange={(newFilter) => {
                        handleUserTransactions(newFilter)
                      }}
                      filters={userTransactionsFilter}
                      textFieldPlaceholder={t('UsersPage.FilterLogsBy')}
                      downloadTransactions={tenantUser.permissions.includes(
                        InfinityEnums.TenantUserPermission
                          .CAN_DOWNLOAD_TRANSACTIONS,
                      )}
                      isLoading={
                        fetchUserListRequestState === RequestState.LOADING ||
                        fetchUserDetailsRequestState === RequestState.LOADING ||
                        fetchUserTransactionsRequestState ===
                          RequestState.LOADING ||
                        (selectedUser?.isInvitedUser &&
                          !selectedUser?.appUserId)
                      }
                      canSort={true}
                      ascending={ascendingTransactions}
                      onSortChange={() => {
                        clearUserTransactions()
                        setUserTransactionsPaginationOffset(0)
                        fetchUserTransactions(
                          userTransactionsFilter,
                          0,
                          selectedUser._id,
                          !ascendingTransactions,
                        )
                        setAscendingTransactions((prev) => {
                          return !prev
                        })
                      }}
                    />
                  )}
                </div>
                <UserTransactionList ascending={ascendingTransactions} />
              </div>
            )}
          </IFScrollbars>
        </LeftContainer>

        {isEdit ? (
          <div className={Styles.RightContainerEdit}>
            <IFTooltipIconsLoading
              onClick={() => confirmDeleteDialog.current.show()}
              title={t('ChargePointDetails.DeleteButtonText')}
              iconColor={Colors.red}
              iconBackgroundColor={Colors.red}
              Icon={IFsvg.Delete}
              FilledIcon={IFsvg.Delete}
              isLoading={deleteUserRequestState === RequestState.LOADING}
              tooltipPlacement="left"
              size={26}
              iconClassname={Styles.iconContainer}
              style={{ marginRight: '1rem !important' }}
            />

            <IFTooltipIconsLoading
              onClick={handleCancel}
              title={t('ChargePointDetails.CancelButtonText')}
              Icon={IFsvg.Cancel}
              FilledIcon={IFsvg.Cancel}
              isLoading={false}
              tooltipPlacement="left"
              size={26}
              iconClassname={Styles.iconContainer}
              style={{ marginRight: '1rem !important' }}
            />

            <IFTooltipIconsLoading
              onClick={() => handleSubmit(false)}
              title={t('ChargePointDetails.DoneButtonText')}
              Icon={IFsvg.Done}
              FilledIcon={IFsvg.Done}
              isLoading={
                deleteUserAccessGroupsRequestState === RequestState.LOADING ||
                addUserAccessGroupsRequestState === RequestState.LOADING ||
                addUserSubscriptionsRequestState === RequestState.LOADING ||
                deleteUserSubscriptionsRequestState === RequestState.LOADING
              }
              tooltipPlacement="left"
              size={26}
              iconClassname={Styles.iconContainer}
              style={{ marginRight: '1rem !important' }}
              animationDisabled={false}
            />
          </div>
        ) : (fetchUserListRequestState === RequestState.LOADING ||
            (users.length > 0 && users[selectedIndex].email)) &&
          !selectedUser?.isInvitedUser ? (
          <div className={Styles.RightContainer}>
            <div className={Styles.EditButtonContainer}>
              {fetchUserListRequestState === RequestState.LOADING ||
              fetchUserDetailsRequestState === RequestState.LOADING ? (
                <div className={Styles.iconContainerSkeleton}>
                  <IFSkeleton variant="circular" width="26px" height="26px" />
                </div>
              ) : (
                <IFTooltipIconsLoading
                  onClick={() => {
                    setIsEdit(true)
                  }}
                  title={t('ChargePointDetails.EditButtonText')}
                  Icon={IFsvg.Edit}
                  FilledIcon={IFsvg.Edit}
                  isLoading={false}
                  tooltipPlacement="left"
                  size={26}
                  iconClassname={Styles.iconContainer}
                  style={{ marginRight: '1rem !important' }}
                />
              )}
            </div>
          </div>
        ) : null}
        <IFDialog
          ref={confirmDeleteDialog}
          title={t('UsersPage.DeleteUser')}
          subtitle={t('UsersPage.DeleteUserSubtitle')}
          onAction={() => deleteUser(selectedUser._id)}
          actionButtonIcon={
            <Icon
              sx={{ width: '18px !important', height: '18px !important' }}
              component={DeleteOutlineIcon}
            />
          }
          actionButtonText={t('UsersPage.DeleteButtonText')}
          actionButtonColor={Colors.red}
          confirmationText={selectedUser?.email ? selectedUser.email : ''}
          isLoading={deleteUserRequestState === RequestState.LOADING}
        />
      </div>
    </Fragment>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUser: (userId) => dispatch(UserActions.fetchUserDetails(userId)),
    clearUserTransactions: () => dispatch(UserActions.clearUserTransactions()),
    resetUserState: () => dispatch(UserActions.resetUserState()),
    setUserTransactionsFilter: (newFilter) =>
      dispatch(UserActions.setUserTransactionsFilter(newFilter)),
    setUserTransactionsPaginationOffset: (offset) =>
      dispatch(UserActions.setUserTransactionsPaginationOffset(offset)),
    fetchUserTransactions: (filter, offset, userId, ascending) =>
      dispatch(
        UserActions.fetchUserTransactions(filter, offset, userId, ascending),
      ),
    deleteUser: (userId) => dispatch(UserActions.deleteUser(userId)),
  }
}

const mapStateToProps = (state) => ({
  userTransactionsFilter: UserSelectors.getUserTransactionsFilter(state),
  users: UserSelectors.getUserList(state),
  selectedIndex: UserSelectors.getSelectedUserIndex(state),
  fetchUserListRequestState: UserSelectors.getFetchUserListRequestState(state),
  fetchUserDetailsRequestState:
    UserSelectors.getFetchUserDetailsRequestState(state),
  selectedUser: UserSelectors.getSelectedUser(state),
  fetchUserTransactionsRequestState:
    UserSelectors.getFetchUserTransactionsRequestState(state),
  deleteUserRequestState: UserSelectors.getDeleteUserRequestState(state),
  deleteUserAccessGroupsRequestState:
    UserSelectors.getDeleteUserAccessGroupsRequestState(state),
  addUserAccessGroupsRequestState:
    UserSelectors.getAddUserAccessGroupsRequestState(state),
  addUserSubscriptionsRequestState:
    UserSelectors.getAddUserSubscriptionsRequestState(state),
  deleteUserSubscriptionsRequestState:
    UserSelectors.getDeleteUserSubscriptionsRequestState(state),
  userTransactions: UserSelectors.getUserTransactions(state),
  tenantUser: AuthSelectors.getTenantUser(state),
  fetchUserSessionListRequestState:
    UserSelectors.getFetchUserSessionListRequestState(state),
  userSessions: UserSelectors.getUserSessions(state),
})

UserDetails.propTypes = {
  selectedUser: PropTypes.object,
  selectedIndex: PropTypes.number,
  fetchUserListRequestState: PropTypes.number,
  fetchUserDetailsRequestState: PropTypes.number,
  userTransactionsFilter: PropTypes.arrayOf(PropTypes.object),
  users: PropTypes.arrayOf(PropTypes.object),
  fetchUser: PropTypes.func,
  clearUserTransactions: PropTypes.func,
  resetUserState: PropTypes.func,
  setUserTransactionsFilter: PropTypes.func,
  setUserTransactionsPaginationOffset: PropTypes.func,
  fetchUserTransactions: PropTypes.func,
  fetchUserTransactionsRequestState: PropTypes.number,
  deleteUser: PropTypes.func,
  deleteUserRequestState: PropTypes.number,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails)
