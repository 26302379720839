import * as React from 'react'
import { Accordion, AccordionDetails } from '@material-ui/core'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Styles from './TransactionListItem.module.css'
import colors from 'Theme/Colors'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import Moment from 'moment'
import icons from 'Theme/Icons'
import PropTypes from 'prop-types'
import InfinityEnums from 'Enums/InfinityEnums'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import { withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { isPropsMatch } from 'Utils/PropsMatch'
import moment from 'moment'
import { TransactionStatus, IFText } from 'Components'
import { CreditCard, PhoneIphone } from '@mui/icons-material'

const StyledHyperLink = styled.a`
  font-size: 14;
  vertical-align: middle;
  text-align: center;
  padding-bottom: 2px;
  margin-left: 6px;
  color: ${(props) => props.textColor};
  &:hover {
    color: ${(props) => props.hoverColor} !important;
  }
  &:focus {
    color: ${(props) => props.hoverColor} !important;
  }
`

function TransactionListItem({
  connectorUid,
  connectorType,
  energyConsumption,
  startedAt,
  endedAt,
  processedAt,
  startingChargingTokenType,
  amount,
  stationName,
  chargePointName,
  reservationID = '-',
  subscription,
  chargingLevel,
  tariff,
  chargePointId,
  stationId,
  status,
  currency,
  ...props
}) {
  const { t } = useTranslation()
  const durationHr = parseInt(Moment(endedAt).diff(startedAt, 'hours'))
  const durationMin = moment
    .duration(moment(endedAt).diff(moment(startedAt)))
    .minutes()
  const durationSecs = moment
    .duration(moment(endedAt).diff(moment(startedAt)))
    .seconds()

  const AccordionSummary = withStyles({
    root: {
      '&$expanded': {
        minHeight: 64,
      },
      '&:hover': {
        backgroundColor: colors.ChargePointLogListItemHover,
      },
    },
    content: {
      '&$expanded': {
        margin: '0px',
      },
    },
    expanded: {},
  })(MuiAccordionSummary)

  const ConnectorIconSrc = () => {
    let src = ''
    switch (connectorType) {
      case InfinityEnums.ConnectorType.TYPE_2:
        src = icons.type2({ width: 20, height: 20, color: colors.black })
        break
      case InfinityEnums.ConnectorType.COMBO_CCS:
        src = icons.comboCCS({ width: 20, height: 20, color: colors.black })
        break
      case InfinityEnums.ConnectorType.CHADEMO:
        src = icons.chademo({ width: 20, height: 20, color: colors.black })
        break
      case InfinityEnums.ConnectorType.SCHUKO:
        src = icons.schuko({ width: 20, height: 20, color: colors.black })
        break
      default:
        src = icons.type2({ width: 20, height: 20, color: colors.black })
        break
    }
    return src
  }

  const connectorPower = () => {
    switch (chargingLevel) {
      case InfinityEnums.ChargingSpeedLevels.LEVEL1:
        return icons.normalCharging
      case InfinityEnums.ChargingSpeedLevels.LEVEL2:
        return icons.fastCharging
      case InfinityEnums.ChargingSpeedLevels.LEVEL3:
        return icons.superCharging
      default:
        return icons.normalCharging
    }
  }

  return (
    <div {...props}>
      <Accordion
        className={[Styles.accordion]}
        style={{
          boxShadow: `0px 0px 0px ${colors.ChargePointLogTransactionItemBoxShadow}`,
          borderRadius: '0px',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={Styles.accordionSummary}
        >
          <div className={Styles.container}>
            <TransactionStatus status={status} />

            <div className={Styles.accordionStationWrapper}>
              <img
                className={Styles.ChargingIcon}
                src={connectorPower()}
                alt=""
              />
              <div className={Styles.stationText}>
                <StyledHyperLink
                  hoverColor={colors.primary}
                  textColor={colors.text}
                  target="_blank"
                  href={`/sites/${stationId}/stations/${chargePointId}`}
                >
                  <div>
                    <IFText className={Styles.StationNameText}>
                      {stationName}
                    </IFText>
                  </div>
                  <IFText className={Styles.chargePointName}>
                    {chargePointName}
                  </IFText>
                </StyledHyperLink>
              </div>
            </div>

            <div className={Styles.accordionTextPower}>
              <div className={Styles.flashIcon}>{icons.flash}</div>
              <IFText className={Styles.accordionTextEnergyConsumption}>
                {`${Number(energyConsumption / 1000).toFixed(3)} kWh`}
              </IFText>
            </div>

            <div className={Styles.accordionDuration}>
              <TimerOutlinedIcon
                style={{ height: '18px' }}
                className={Styles.accordionicon}
              />
              <IFText className={Styles.accordionDurationText}>
                {durationHr !== 0 ? `${durationHr}h` : ''}
                {durationMin === '00' && durationHr !== 0
                  ? ''
                  : ` ${durationMin}m`}
              </IFText>
            </div>

            <div className={Styles.amountContainer}>
              <IFText className={Styles.currencyText}>{currency.code}</IFText>
              <IFText className={Styles.amount}>
                {Number(amount)?.toFixed(currency.decimalPlaces)}
              </IFText>
            </div>

            <div className={Styles.accordionDateContainer}>
              <IFText className={Styles.accordionTime}>
                {Moment(processedAt).format('HH:mm')}
              </IFText>
              <IFText className={Styles.accordionDate}>
                {Moment(processedAt).format('MMM DD, yyy')}
              </IFText>
            </div>
          </div>
        </AccordionSummary>

        <AccordionDetails className={Styles.accordionDetails}>
          <div className={Styles.container}>
            <div className={Styles.leftDetailsContainer}>
              <div className={Styles.startDateContainer}>
                <IFText className={Styles.startText}>
                  {t('UserTransaction.Start')}
                </IFText>
                <IFText className={Styles.accordionStartDate}>
                  {Moment(startedAt).format('MMM DD, yyy \xa0 HH:mm:ss')}
                </IFText>
              </div>

              <div className={Styles.endDateContainer}>
                <IFText className={Styles.endText}>
                  {t('UserTransaction.End')}
                </IFText>
                <IFText className={Styles.accordionEndDate}>
                  {Moment(endedAt).format('MMM DD, yyy \xa0 HH:mm:ss')}
                </IFText>
              </div>

              <div className={Styles.startDateContainer}>
                <IFText className={Styles.durationText}>
                  {t('UserTransaction.ReservationID')}
                </IFText>

                <IFText className={Styles.reservationID}>
                  {reservationID}
                </IFText>
              </div>
            </div>

            <div className={Styles.leftDetailsContainer}>
              <div className={Styles.startDateContainer}>
                <IFText className={Styles.connectorTypeText}>
                  {t('UserTransaction.TokenType')}
                </IFText>
                <div>
                  {startingChargingTokenType ===
                  InfinityEnums.ChargingTokenType.VIRTUAL ? (
                    <PhoneIphone sx={{ fontSize: 20, alignSelf: 'center' }} />
                  ) : (
                    <CreditCard sx={{ fontSize: 20, alignSelf: 'center' }} />
                  )}
                </div>
              </div>
              <div className={Styles.startDateContainer}>
                <IFText className={Styles.reservationText}>
                  {t('UserTransaction.ConnectorType')}
                </IFText>

                <div className={Styles.accordionicon}>{ConnectorIconSrc()}</div>
              </div>
              <div className={Styles.startDateContainer}>
                <IFText className={Styles.connectorIDText}>
                  {t('UserTransaction.ConnectorID')}
                </IFText>
                <div className={Styles.ConnectorIdContainer}>
                  <IFText className={Styles.connectorID}>{connectorUid}</IFText>
                </div>
              </div>
            </div>

            <div className={Styles.leftDetailsContainer}>
              <div className={Styles.startDateContainer}>
                <IFText className={Styles.subscriptionText}>
                  {t('UserTransaction.Subscription')}
                </IFText>
                <IFText className={Styles.subscription}>{subscription}</IFText>
              </div>

              <div className={Styles.startDateContainer}>
                <IFText className={Styles.chargingLevelText}>
                  {t('UserTransaction.ChargingLevel')}
                </IFText>

                <div className={Styles.chargingLevelFlashContainer}>
                  {[
                    ...Array(
                      chargingLevel === InfinityEnums.ChargingSpeedLevels.LEVEL1
                        ? 1
                        : chargingLevel ===
                          InfinityEnums.ChargingSpeedLevels.LEVEL2
                        ? 2
                        : 3,
                    ),
                  ].map(() => {
                    return (
                      <div className={Styles.chargingLevelFlashIcon}>
                        {icons.flash}
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className={Styles.startDateContainer}>
                <IFText className={Styles.tariffText}>
                  {t('UserTransaction.TariffText')}
                </IFText>
                <IFText
                  className={Styles.tariff}
                >{`${tariff} ${currency.code}/kWh`}</IFText>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

TransactionListItem.propTypes = {
  connectorUid: PropTypes.number.isRequired,
  connectorType: PropTypes.string,
  energyConsumption: PropTypes.number.isRequired,
  startedAt: PropTypes.string.isRequired,
  endedAt: PropTypes.string.isRequired,
  processedAt: PropTypes.string.isRequired,
  startingChargingToken: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  stationName: PropTypes.string.isRequired,
  chargePointName: PropTypes.string.isRequired,
  reservationID: PropTypes.string.isRequired,
  subscription: PropTypes.string.isRequired,
  chargingLevel: PropTypes.number.isRequired,
  tariff: PropTypes.number.isRequired,
}

function shouldSkipRender(prevProps, nextProps) {
  return isPropsMatch(prevProps, nextProps, [
    'connectorUid',
    'connectorType',
    'energyConsumption',
    'startedAt',
    'endedAt',
    'processedAt',
    'startingChargingTokenType',
    'amount',
    'stationName',
    'chargePointName',
    'reservationID',
    'subscription',
    'chargingLevel',
    'tariff',
    'chargePointId',
    'stationId',
  ])
}
export default React.memo(TransactionListItem, shouldSkipRender)
