import React, { useState, useEffect, useRef, Fragment } from 'react'
import { connect } from 'react-redux'
import Styles from './AddStationModal.module.css'
import { Colors } from 'Theme'
import { useTranslation } from 'react-i18next'
import StationActions from 'Stores/Station/Actions'
import StationSelectors from 'Stores/Station/Selectors'
import RequestState from 'Enums/RequestState'
import {
  IFMap,
  IFModal,
  IFText,
  IFTextInput,
  IFTooltipIconsLoading,
  IFsvg,
  IFToggle,
} from 'Components'

import PropTypes from 'prop-types'
import validator from 'validator'
import AuthSelectors from 'Stores/Auth/Selectors'
import Icon from '@material-ui/core/Icon'
import AddIcon from '@mui/icons-material/Add'
import { makeStyles } from '@material-ui/core/styles'
import AddCityModal from 'Containers/AddCityModal/AddCityModal'
import FormControl from '@material-ui/core/FormControl'
import { SelectPicker } from 'rsuite'

const useStyles = makeStyles({
  root: {
    boxShadow: `0px 0px 4px ${Colors.black} `,
    borderRadius: '6px',
    marginTop: '1px',
  },
})

const AddStationModal = React.forwardRef(
  (
    {
      tenantUser,
      addStationRequestState,
      addStation,
      cities,
      stationsFilter,
      addCityRequestState,
    },
    ref,
  ) => {
    const initialCoordinates = '30.0444 , 31.2357'
    const [stationName, setStationName] = useState('')
    const [streetName, setStreetName] = useState('')
    const [city, setCity] = useState('')
    const [coordinates, setCoordinates] = useState(initialCoordinates)
    const [citiesOptions, setCitiesOptions] = useState([])
    const [isPrivateAccess, setIsPrivateAccess] = useState(false)
    const addCityModalRef = useRef()
    const isPrivateAccessRef = useRef()

    const Classes = useStyles()
    const { t } = useTranslation()

    useEffect(() => {
      let citiesOptionsNew = []
      for (const index in cities) {
        citiesOptionsNew.push({
          label: (
            <IFText style={{ color: Colors.text }}>{cities[index]}</IFText>
          ),
          value: cities[index],
        })
      }
      setCitiesOptions(citiesOptionsNew)
    }, [stationsFilter])

    useEffect(() => {
      if (addCityRequestState === RequestState.SUCCEEDED) {
        setCity(addCityModalRef.current.returnLastAddedCity())
      }
    }, [addCityRequestState])

    useEffect(() => {
      if (addStationRequestState === RequestState.SUCCEEDED) {
        ref.current.handleClose()
      }
    }, [addStationRequestState])

    const addNewStation = () => {
      const station = {
        name: stationName,
        location: {
          streetName: streetName,
          city: city,
          latitude: Number(coordinates.replaceAll(' ', '').split(',')[0]),
          longitude: Number(coordinates.replaceAll(' ', '').split(',')[1]),
        },
        isPrivateAccess: isPrivateAccess,
        tenant: tenantUser.tenant,
      }
      addStation(station)
    }

    const addNewStationRef = useRef(addNewStation)

    useEffect(() => {
      addNewStationRef.current = addNewStation
    })

    const handleClose = () => {
      setStationName('')
      setStreetName('')
      setCity('')
      setCoordinates(initialCoordinates)
      setIsPrivateAccess(false)
      if (isPrivateAccessRef.current) isPrivateAccessRef.current.setValue(false)
    }
    return (
      <Fragment>
        <IFModal
          width={'65rem'}
          onClose={handleClose}
          title={t('AddStationModal.AddSite')}
          ref={ref}
        >
          <Fragment>
            <div className={Styles.bottomWrapper}>
              <div className={Styles.bottomContainer}>
                <div className={Styles.bottomItem}>
                  <IFText
                    className={Styles.textTitle}
                    style={{ color: Colors.text, marginTop: 0 }}
                  >
                    {t('AddStationModal.SiteName')}
                  </IFText>
                  <IFTextInput
                    style={{ color: Colors.text }}
                    className={Styles.textInput}
                    fontSize={16}
                    value={stationName}
                    onChange={(e) => {
                      setStationName(e.target.value)
                    }}
                    maxWidth={'21rem'}
                  />
                  <IFText
                    className={Styles.textTitle}
                    style={{ color: Colors.text, marginTop: 0 }}
                  >
                    {t('AddStationModal.StreetName')}
                  </IFText>
                  <IFTextInput
                    style={{ color: Colors.text }}
                    className={Styles.textInput}
                    fontSize={16}
                    value={streetName}
                    onChange={(e) => {
                      setStreetName(e.target.value)
                    }}
                    maxWidth={'21rem'}
                  />

                  <IFText
                    className={Styles.textTitle}
                    style={{ color: Colors.text }}
                  >
                    {t('AddStationModal.City')}
                  </IFText>
                  <div className={Styles.cityInput}>
                    <FormControl
                      className={Classes.root}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <SelectPicker
                        size="sm"
                        preventOverflow={true}
                        menuStyle={{
                          color: Colors.text,
                          zIndex: 1500,
                        }}
                        appearance="subtle"
                        searchable={true}
                        cleanable={false}
                        onSelect={(value) => setCity(value)}
                        data={citiesOptions}
                        value={city}
                        className={Styles.selectPicker}
                      />
                    </FormControl>
                    <div className={Styles.addCityIcon}>
                      <Icon
                        className={Styles.addCityButton}
                        sx={{ fontSize: 20, color: Colors.black }}
                        component={AddIcon}
                        onClick={() => addCityModalRef.current.show()}
                      />
                    </div>
                  </div>
                  <IFText
                    className={Styles.textTitle}
                    style={{ color: Colors.text }}
                  >
                    {t('AddStationModal.Coordinates')}
                  </IFText>
                  <IFTextInput
                    style={{ color: Colors.text }}
                    className={Styles.textInput}
                    value={coordinates}
                    fontSize={16}
                    onChange={(e) => {
                      setCoordinates(e.target.value)
                    }}
                    maxWidth={'21rem'}
                  />
                  <IFText
                    className={Styles.textTitle}
                    style={{ color: Colors.text }}
                  >
                    {t('AddStationModal.Access')}
                  </IFText>
                  <IFToggle
                    ref={isPrivateAccessRef}
                    height={22}
                    fontSize={12}
                    checkedText={t('AddStationModal.Public')}
                    uncheckedText={t('AddStationModal.Private')}
                    readOnly={false}
                    defaultChecked={!isPrivateAccess}
                    onChange={(value) => {
                      setIsPrivateAccess(!value)
                    }}
                  />
                </div>
                <div style={{ flexGrow: 2.5, flexShrink: 0 }}>
                  <IFMap
                    style={{ height: '100%' }}
                    latitude={coordinates.replaceAll(' ', '').split(',')[0]}
                    longitude={coordinates.replaceAll(' ', '').split(',')[1]}
                  />
                </div>
              </div>
              <div
                className={Styles.actionsContainer}
                style={{ borderLeft: `1px solid ${Colors.DividerColor}` }}
              >
                <div className={Styles.buttonsContainer}>
                  <IFTooltipIconsLoading
                    onClick={() => {
                      addNewStationRef.current()
                    }}
                    tooltipPlacement="left"
                    title={t('AddStationModal.Add')}
                    isDead={
                      validator.isEmpty(stationName) ||
                      validator.isEmpty(streetName) ||
                      validator.isEmpty(city) ||
                      validator.isEmpty(coordinates) ||
                      initialCoordinates === coordinates ||
                      !(
                        coordinates.replaceAll(' ', '').split(',')[0] &&
                        validator.isFloat(
                          coordinates.replaceAll(' ', '').split(',')[0],
                        ) &&
                        coordinates.replaceAll(' ', '').split(',')[1] &&
                        validator.isFloat(
                          coordinates.replaceAll(' ', '').split(',')[1],
                        )
                      )
                    }
                    isLoading={addStationRequestState === RequestState.LOADING}
                    Icon={IFsvg.Done}
                    FilledIcon={IFsvg.Done}
                    iconColor={Colors.primary}
                    size={26}
                    iconClassname={Styles.tooltipIcons}
                    animationDisabled={false}
                  />
                </div>
              </div>
            </div>
          </Fragment>
        </IFModal>
        <AddCityModal ref={addCityModalRef} />
      </Fragment>
    )
  },
)
AddStationModal.propTypes = {
  tenantUser: PropTypes.object,
  addStationRequestState: PropTypes.number,
  addStation: PropTypes.func,
}
function mapDispatchToProps(dispatch) {
  return {
    addStation: (station) => dispatch(StationActions.addStation(station)),
  }
}

const mapStateToProps = (state) => ({
  addStationRequestState: StationSelectors.getAddStationRequestState(state),
  tenantUser: AuthSelectors.getTenantUser(state),
  cities: StationSelectors.getCities(state),
  stationsFilter: StationSelectors.getStationsFilter(state),
  addCityRequestState: StationSelectors.getAddCityRequestState(state),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(AddStationModal)
