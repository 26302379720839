import { put, call, select, delay } from 'redux-saga/effects'
import {
  stationApiService,
  chargePointApiService,
  handleError,
  chargingProfileApiService,
} from 'Services/ApiService.js'
import StationActions from 'Stores/Station/Actions'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import StationSelectors from 'Stores/Station/Selectors'
import { toaster } from 'rsuite'
import { IFToastMessage } from 'Components'
import i18n from 'i18next'
var base64 = require('base-64')

export function* fetchChargePointList({ stationId }) {
  yield put(ChargePointActions.fetchChargePointListLoading())
  try {
    const { data } = yield call(stationApiService.get, `/${stationId}`)
    const stationsFilter = yield select(StationSelectors.getStationsFilter)
    yield put(
      ChargePointActions.fetchChargePointListSuccess(
        data.chargePoints,
        stationsFilter,
      ),
    )
  } catch (e) {
    yield put(ChargePointActions.fetchChargePointListFail(e))
    handleError(e)
  }
}
export function* fetchChargePoint({ chargePointId }) {
  yield put(ChargePointActions.fetchChargePointLoading())
  try {
    const { data } = yield call(chargePointApiService.get, `/${chargePointId}`)
    yield put(ChargePointActions.fetchChargePointSuccess(data.chargePoint))
    return data
  } catch (e) {
    yield put(ChargePointActions.fetchChargePointFail(e))
    handleError(e)
  }
}

export function* fetchChargePointLogList({
  filter,
  offset,
  chargePointId,
  ascending,
}) {
  if (offset === 0)
    yield put(ChargePointActions.fetchChargePointLogListLoading())
  try {
    if (filter != null) {
      filter = base64.encode(JSON.stringify(filter))
    }

    const { data } = yield call(
      chargePointApiService.get,
      `/${chargePointId}/logs`,
      {
        params: {
          filter: filter,
          offset: offset,
          ascending: ascending,
        },
      },
    )

    yield put(
      ChargePointActions.fetchChargePointLogListSuccess(
        offset,
        data.chargePointLogs,
        data.nextOffset,
      ),
    )
  } catch (e) {
    yield put(ChargePointActions.fetchChargePointLogListFail(e))
    handleError(e)
  }
}

export function* fetchChargePointTransactionList({
  filter,
  offset,
  chargePointId,
  ascending,
}) {
  if (offset === 0)
    yield put(ChargePointActions.fetchChargePointTransactionListLoading())
  try {
    if (filter != null) {
      filter = base64.encode(JSON.stringify(filter))
    }

    const { data } = yield call(
      chargePointApiService.get,
      `/${chargePointId}/transactions`,
      {
        params: {
          filter: filter,
          offset: offset,
          ascending: ascending,
        },
      },
    )

    yield put(
      ChargePointActions.fetchChargePointTransactionListSuccess(
        offset,
        data.transactions,
        data.nextOffset,
      ),
    )
  } catch (e) {
    yield put(ChargePointActions.fetchChargePointTransactionListFail(e))
    handleError(e)
  }
}

export function* fetchChargePointConfigList({ chargePointId }) {
  yield put(ChargePointActions.fetchChargePointConfigListLoading())
  try {
    const { data } = yield call(
      chargePointApiService.get,
      `/${chargePointId}/configuration`,
    )

    yield put(ChargePointActions.fetchChargePointConfigListSuccess(data))
  } catch (e) {
    e = yield e
    yield put(ChargePointActions.fetchChargePointConfigListFail(e))
    handleError(e)
  }
}

export function* updateChargePoint({
  index,
  chargePointData,
  onComplete = () => {},
}) {
  yield put(ChargePointActions.updateChargePointLoading())
  try {
    const selectedChargePoint = yield select(
      ChargePointSelectors.getSelectedChargePoint,
    )
    const { data } = yield call(
      chargePointApiService.patch,
      `/${selectedChargePoint._id}`,
      {
        ...chargePointData,
      },
    )

    yield put(ChargePointActions.updateChargePointSuccess(index, data))
    onComplete()
  } catch (e) {
    yield put(ChargePointActions.updateChargePointFail(e))
    handleError(e)
  }
}

export function* moveChargePoint({ index, chargePointData }) {
  yield put(ChargePointActions.moveChargePointLoading())
  try {
    const selectedChargePoint = yield select(
      ChargePointSelectors.getSelectedChargePoint,
    )
    const { data } = yield call(
      chargePointApiService.patch,
      `/${selectedChargePoint._id}`,
      {
        ...chargePointData,
      },
    )

    yield put(ChargePointActions.moveChargePointSuccess(index, data))
  } catch (e) {
    yield put(ChargePointActions.moveChargePointFail(e))
    handleError(e)
  }
}

export function* updateChargePointConfig({
  chargePointId,
  key,
  value,
  onSuccess,
  onFail,
}) {
  yield put(ChargePointActions.updateChargePointConfigLoading())
  try {
    const { data } = yield call(
      chargePointApiService.patch,
      `/${chargePointId}/configuration`,
      {
        key,
        value,
      },
    )
    yield put(ChargePointActions.updateChargePointConfigSuccess(key, value))
    onSuccess(data.status)
  } catch (e) {
    onFail()
    yield put(ChargePointActions.updateChargePointConfigFail(e))
    handleError(e)
  }
}

export function* deleteChargePoint({ chargePointId }) {
  yield put(ChargePointActions.deleteChargePointLoading())
  try {
    const selectedChargePoint = yield select(
      ChargePointSelectors.getSelectedChargePoint,
    )
    const { data } = yield call(
      chargePointApiService.delete,
      `/${chargePointId}`,
    )
    yield put(StationActions.deleteStationChargePoint(selectedChargePoint.uid))
    yield put(ChargePointActions.deleteChargePointSuccess(chargePointId))
  } catch (e) {
    yield put(ChargePointActions.deleteChargePointFail(e))
    handleError(e)
  }
}

export function* addChargePoint({ chargePoint }) {
  yield put(ChargePointActions.addChargePointLoading())
  try {
    const { data } = yield call(chargePointApiService.post, '/', {
      ...chargePoint,
    })
    yield put(StationActions.addStationChargePoint(data))
    yield put(ChargePointActions.addChargePointSuccess(data))
  } catch (e) {
    yield put(ChargePointActions.addChargePointFail(e))
    handleError(e)
  }
}

const CHUNK_SIZE = 5 * 1024 * 1024 // 5MB chunks
const MAX_RETRIES = 4
const RETRY_DELAY = 2000 // 2s

function* uploadChunk({
  chunkIndex,
  file,
  totalChunks,
  chunkSize,
  maxRetries,
  retryDelay,
}) {
  const start = chunkIndex * chunkSize
  const end = Math.min(start + chunkSize, file.size)
  const chunk = file.slice(start, end)

  const formData = new FormData()
  formData.append('file', chunk)

  let attempts = 0

  // Attempt multiple times if it fails
  while (attempts < maxRetries) {
    try {
      const response = yield call(
        chargePointApiService.post,
        `/uploadFirmware`,
        formData,
        {
          headers: {
            'X-Chunk-Index': chunkIndex,
            'X-Total-Chunks': totalChunks,
            'X-Filename': file.name,
          },
        },
      )
      return response
    } catch (error) {
      attempts++
      if (attempts >= maxRetries) {
        throw error
      }
      // Wait before retrying (exponential)
      yield delay(retryDelay * attempts)
    }
  }
}

export function* uploadChargePointFirmware({
  file,
  onUploadProgress = () => {},
  onUploadDone = () => {},
}) {
  try {
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE)

    // Loop through all chunks
    for (let i = 0; i < totalChunks; i++) {
      yield call(uploadChunk, {
        chunkIndex: i,
        file,
        totalChunks,
        chunkSize: CHUNK_SIZE,
        maxRetries: MAX_RETRIES,
        retryDelay: RETRY_DELAY,
      })

      // Update progress
      const progressPercent = ((i + 1) / totalChunks) * 100
      onUploadProgress(Number(progressPercent.toFixed(1)))
    }
    onUploadDone()
  } catch (e) {
    handleError(e)
  }
}

export function* updateChargePointFirmware({ file, chargePointId }) {
  yield put(ChargePointActions.updateChargePointFirmwareLoading())
  try {
    let body = {}
    if (
      file.startsWith('http://') ||
      file.startsWith('https://') ||
      file.startsWith('ftp://')
    ) {
      body.url = file
    } else {
      body.filename = file
    }
    const { data } = yield call(
      chargePointApiService.post,
      `/${chargePointId}/updateFirmware`,
      {
        ...body,
      },
    )
    yield put(ChargePointActions.updateChargePointFirmwareSuccess())
    toaster.push(
      <IFToastMessage
        type="success"
        text={i18n.t('ChargePointSaga.UpdateChargePointFirmware')}
      />,
      {
        placement: 'topEnd',
      },
    )
  } catch (e) {
    yield put(ChargePointActions.updateChargePointFirmwareFail(e))
    handleError(e)
  }
}
export function* fetchChargePointSubscriptionsList({ chargePointId }) {
  yield put(ChargePointActions.fetchChargePointSubscriptionsLoading())
  try {
    const { data } = yield call(
      chargePointApiService.get,
      `/${chargePointId}/subscriptions`,
    )
    const chargePointSubscriptions = data.subscriptions
    yield put(
      ChargePointActions.fetchChargePointSubscriptionsSuccess(
        chargePointSubscriptions,
      ),
    )
  } catch (e) {
    yield put(ChargePointActions.fetchChargePointSubscriptionsFail(e))
    handleError(e)
  }
}

export function* deleteChargePointSubscriptions({
  chargePointId,
  subscriptions,
}) {
  yield put(ChargePointActions.deleteChargePointSubscriptionsLoading())
  try {
    const { data } = yield call(
      chargePointApiService.delete,
      `/${chargePointId}/subscriptions/bulk`,
      { data: { subscriptions } },
    )
    yield put(
      ChargePointActions.deleteChargePointSubscriptionsSuccess(subscriptions),
    )
  } catch (e) {
    yield put(ChargePointActions.deleteChargePointSubscriptionsFail(e))
    handleError(e)
  }
}

export function* fetchChargePointUploadDiagnostics({ chargePointId }) {
  yield put(ChargePointActions.fetchChargePointUploadDiagnosticsLoading())
  try {
    const { data } = yield call(
      chargePointApiService.get,
      `/${chargePointId}/uploadDiagnostics`,
    )
    yield put(
      ChargePointActions.fetchChargePointUploadDiagnosticsSuccess(
        data.uploadDiagnostics,
      ),
    )
  } catch (e) {
    yield put(ChargePointActions.fetchChargePointUploadDiagnosticsFail(e))
    handleError(e)
  }
}
export function* addChargePointSubscriptions({ chargePointId, subscriptions }) {
  yield put(ChargePointActions.addChargePointSubscriptionsLoading())
  try {
    const { data } = yield call(
      chargePointApiService.post,
      `/${chargePointId}/subscriptions/bulk`,
      { subscriptions },
    )
    yield put(
      ChargePointActions.addChargePointSubscriptionsSuccess(
        data.newChargePointSubscriptions,
      ),
    )
  } catch (e) {
    yield put(ChargePointActions.addChargePointSubscriptionsFail(e))
    handleError(e)
  }
}

export function* fetchChargePointPrivacyScheduleList({ chargePointId }) {
  yield put(ChargePointActions.fetchChargePointPrivacyScheduleLoading())
  try {
    const { data } = yield call(
      chargePointApiService.get,
      `/${chargePointId}/privacyRule`,
    )
    const privacySchedule = data
    yield put(
      ChargePointActions.fetchChargePointPrivacyScheduleSuccess(
        privacySchedule,
      ),
    )
  } catch (e) {
    yield put(ChargePointActions.fetchChargePointPrivacyScheduleFail(e))
    handleError(e)
  }
}
export function* fetchChargePointAccessGroups({ chargePointId }) {
  yield put(ChargePointActions.fetchChargePointAccessGroupsLoading())
  try {
    const { data } = yield call(
      chargePointApiService.get,
      `/${chargePointId}/accessGroups`,
    )

    yield put(
      ChargePointActions.fetchChargePointAccessGroupsSuccess(data.accessGroups),
    )
  } catch (e) {
    yield put(ChargePointActions.fetchChargePointAccessGroupsFail(e))
    handleError(e)
  }
}

export function* requestChargePointUploadDiagnostics({
  chargePointId,
  dateTime,
}) {
  yield put(ChargePointActions.requestChargePointUploadDiagnosticsLoading())
  try {
    const { data } = yield call(
      chargePointApiService.post,
      `/${chargePointId}/uploadDiagnostics`,
      {
        startTime: dateTime[0],
        stopTime: dateTime[1],
      },
    )
    yield put(
      ChargePointActions.requestChargePointUploadDiagnosticsSuccess(
        data.response,
      ),
    )
    toaster.push(
      <IFToastMessage
        type="success"
        text={i18n.t('ChargePointSaga.RequestChargePointDiagnostics')}
      />,
      {
        placement: 'topEnd',
      },
    )
  } catch (e) {
    yield put(ChargePointActions.requestChargePointUploadDiagnosticsFail(e))
    handleError(e)
  }
}
export function* deleteChargePointPrivacyRules({
  chargePointId,
  privacyRules,
}) {
  yield put(ChargePointActions.deleteChargePointPrivacyRulesLoading())
  try {
    const { data } = yield call(
      chargePointApiService.delete,
      `/${chargePointId}/privacyRule/bulk`,
      { data: { privacyRules } },
    )
    yield put(
      ChargePointActions.deleteChargePointPrivacyRulesSuccess(privacyRules),
    )
  } catch (e) {
    yield put(ChargePointActions.deleteChargePointPrivacyRulesFail(e))
    handleError(e)
  }
}
export function* deleteChargePointAccessGroupsBulk({
  chargePointId,
  accessGroups,
}) {
  yield put(ChargePointActions.deleteChargePointAccessGroupsBulkLoading())
  try {
    const ids = accessGroups.map((group) => group.id)
    const { data } = yield call(
      chargePointApiService.delete,
      `/${chargePointId}/accessGroups/bulk`,
      {
        data: { groupIds: ids },
      },
    )

    yield put(ChargePointActions.deleteChargePointAccessGroupsBulkSuccess(ids))
  } catch (e) {
    yield put(ChargePointActions.deleteChargePointAccessGroupsBulkFail(e))
    handleError(e)
  }
}

export function* addChargePointPrivacyRules({ chargePointId, privacyRules }) {
  yield put(ChargePointActions.addChargePointPrivacyRulesLoading())
  try {
    const { data } = yield call(
      chargePointApiService.post,
      `/${chargePointId}/privacyRule/bulk`,
      { privacyRules },
    )
    yield put(
      ChargePointActions.addChargePointPrivacyRulesSuccess(
        data.newChargePointPrivacyRules,
      ),
    )
  } catch (e) {
    yield put(ChargePointActions.addChargePointPrivacyRulesFail(e))
    handleError(e)
  }
}
export function* addChargePointAccessGroupsBulk({
  chargePointId,
  accessGroups,
}) {
  yield put(ChargePointActions.addChargePointAccessGroupsBulkLoading())
  try {
    const { data } = yield call(
      chargePointApiService.post,
      `/${chargePointId}/accessGroups/bulk`,
      {
        groupIds: accessGroups,
      },
    )

    yield put(
      ChargePointActions.addChargePointAccessGroupsBulkSuccess(
        data.accessGroups,
      ),
    )
  } catch (e) {
    yield put(ChargePointActions.addChargePointAccessGroupsBulkFail(e))
    handleError(e)
  }
}

export function* fetchChargePointUptimeStatistics({ chargePointId, filter }) {
  yield put(ChargePointActions.fetchChargePointUptimeStatisticsLoading())
  try {
    if (filter != null) {
      filter = base64.encode(JSON.stringify(filter))
    }
    const { data } = yield call(
      chargePointApiService.get,
      `/${chargePointId}/uptimeStatistics`,
      {
        params: {
          filter: filter,
        },
      },
    )

    yield put(ChargePointActions.fetchChargePointUptimeStatisticsSuccess(data))
  } catch (e) {
    yield put(ChargePointActions.fetchChargePointUptimeStatisticsFail(e))
    handleError(e)
  }
}

export function* fetchConnectorsUtilization({ chargePointId, filter }) {
  yield put(ChargePointActions.fetchConnectorsUtilizationLoading())
  try {
    if (filter != null) {
      filter = base64.encode(JSON.stringify(filter))
    }
    const { data } = yield call(
      chargePointApiService.get,
      `/${chargePointId}/connectorUtilization`,
      {
        params: {
          filter: filter,
        },
      },
    )

    yield put(ChargePointActions.fetchConnectorsUtilizationSuccess(data))
  } catch (e) {
    yield put(ChargePointActions.fetchConnectorsUtilizationFail(e))
    handleError(e)
  }
}

export function* fetchChargePointSubscribedAlerts({ chargePointId }) {
  yield put(ChargePointActions.fetchChargePointSubscribedAlertsLoading())
  try {
    const { data } = yield call(
      chargePointApiService.get,
      `/${chargePointId}/subscribedAlerts`,
    )
    yield put(ChargePointActions.fetchChargePointSubscribedAlertsSuccess(data))
  } catch (e) {
    yield put(ChargePointActions.fetchChargePointSubscribedAlertsFail(e))
    handleError(e)
  }
}

export function* fetchChargePointAlerts({ chargePointId }) {
  yield put(ChargePointActions.fetchChargePointAlertsLoading())
  try {
    const { data } = yield call(
      chargePointApiService.get,
      `/${chargePointId}/alerts`,
    )
    yield put(ChargePointActions.fetchChargePointAlertsSuccess(data))
  } catch (e) {
    yield put(ChargePointActions.fetchChargePointAlertsFail(e))
    handleError(e)
  }
}

export function* updateChargePointAlerts({
  chargePointId,
  alertChanges,
  onResponse,
}) {
  yield put(ChargePointActions.updateChargePointAlertsLoading())
  try {
    const { data } = yield call(
      chargePointApiService.patch,
      `/${chargePointId}/alerts`,
      {
        ...alertChanges,
      },
    )
    yield put(
      ChargePointActions.updateChargePointAlertsSuccess(data, alertChanges),
    )
    onResponse()
  } catch (e) {
    yield put(ChargePointActions.updateChargePointAlertsFail(e))
    handleError(e)
  }
}
export function* fetchChargePointLocalList({ chargePointId }) {
  yield put(ChargePointActions.fetchChargePointLocalListLoading())
  try {
    const { data } = yield call(
      chargePointApiService.get,
      `/${chargePointId}/localList`,
    )
    yield put(ChargePointActions.fetchChargePointLocalListSuccess(data))
  } catch (e) {
    yield put(ChargePointActions.fetchChargePointLocalListFail(e))
    handleError(e)
  }
}

export function* fetchChargePointChargingProfiles({
  chargePointId,
  connectorId,
}) {
  yield put(ChargePointActions.fetchChargePointChargingProfilesLoading())
  try {
    const { data } = yield call(
      chargePointApiService.get,
      `/${chargePointId}/connectors/${connectorId}/chargingProfiles`,
    )
    yield put(ChargePointActions.fetchChargePointChargingProfilesSuccess(data))
  } catch (e) {
    yield put(ChargePointActions.fetchChargePointChargingProfilesFail(e))
    handleError(e)
  }
}

export function* updateChargePointLocalList({
  chargePointId,
  chargingTokenUIDs,
  userIds,
  updateType,
  expiryDate,
  version,
  onComplete,
}) {
  yield put(ChargePointActions.updateChargePointLocalListLoading())
  try {
    const { data } = yield call(
      chargePointApiService.post,
      `/${chargePointId}/localList`,
      {
        chargingTokenUIDs,
        userIds,
        updateType,
        expiryDate,
        version,
      },
    )

    yield put(ChargePointActions.updateChargePointLocalListSuccess())
    onComplete?.()
  } catch (e) {
    yield put(ChargePointActions.updateChargePointLocalListFail(e))
    handleError(e)
  }
}
export function* fetchChargePointCompositeSchedule({
  chargePointId,
  connectorId,
  duration,
  chargingRateUnitType,
}) {
  yield put(ChargePointActions.fetchChargePointCompositeScheduleLoading())
  try {
    const { data } = yield call(
      chargePointApiService.get,
      `/${chargePointId}/connectors/${connectorId}/compositeSchedule`,
      {
        params: {
          duration: duration,
          chargingRateUnitType: chargingRateUnitType,
        },
      },
    )
    yield put(ChargePointActions.fetchChargePointCompositeScheduleSuccess(data))
  } catch (e) {
    yield put(ChargePointActions.fetchChargePointCompositeScheduleFail(e))
    handleError(e)
  }
}

export function* clearChargePointChargingProfile({
  chargePointId,
  chargingProfileId,
  connectorId,
  stackLevel,
  chargingProfilePurpose,
}) {
  yield put(ChargePointActions.clearChargePointChargingProfileLoading())
  try {
    const { data } = yield call(
      chargePointApiService.delete,
      `/${chargePointId}/clearChargingProfile`,
      {
        data: {
          chargingProfileId,
          connectorId,
          stackLevel,
          chargingProfilePurpose,
        },
      },
    )
    yield put(ChargePointActions.clearChargePointChargingProfileSuccess(data))
  } catch (e) {
    yield put(ChargePointActions.clearChargePointChargingProfileFail(e))
    handleError(e)
  }
}

export function* setChargePointChargingProfile({
  chargePointId,
  chargingProfileId,
  connectorId,
}) {
  yield put(ChargePointActions.setChargePointChargingProfileLoading())
  try {
    const { data } = yield call(
      chargePointApiService.post,
      `/${chargePointId}/setChargingProfile`,
      {
        chargingProfileId,
        connectorId,
      },
    )
    yield put(ChargePointActions.setChargePointChargingProfileSuccess(data))
  } catch (e) {
    yield put(ChargePointActions.setChargePointChargingProfileFail(e))
    handleError(e)
  }
}

export function* createChargePointChargingProfile({
  chargingProfile,
  chargePointId,
  connectorId,
}) {
  yield put(ChargePointActions.createChargePointChargingProfileLoading())
  try {
    const { data } = yield call(chargingProfileApiService.post, `/`, {
      ...chargingProfile,
    })
    yield put(ChargePointActions.createChargePointChargingProfileSuccess())
    yield* setChargePointChargingProfile({
      chargePointId: chargePointId,
      chargingProfileId: data.profile.uid,
      connectorId: connectorId,
    })
  } catch (e) {
    yield put(ChargePointActions.createChargePointChargingProfileFail(e))
    handleError(e)
  }
}

export function* fetchConnectorTypes({}) {
  yield put(ChargePointActions.fetchConnectorTypesLoading())
  try {
    const { data } = yield call(chargePointApiService.get, '/filters')
    yield put(ChargePointActions.fetchConnectorTypesSuccess(data.filters))
  } catch (e) {
    yield put(ChargePointActions.fetchConnectorTypesFail(e))
    handleError(e)
  }
}
