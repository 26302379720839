import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import IFModal from 'Components/IFModal/IFModal'
import { Colors } from 'Theme'
import { useTranslation } from 'react-i18next'
import { IFButton, IFText, IFTextInput } from 'Components'
import Styles from './FirmwareModal.module.css'
import Dropzone from 'react-dropzone'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import CancelIcon from '@mui/icons-material/Cancel'
import Icon from '@material-ui/core/Icon'
import { Divider } from '@material-ui/core'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import RequestState from 'Enums/RequestState'
import { Progress } from 'rsuite'
import AuthSelectors from 'Stores/Auth/Selectors'
import InfinityEnums from 'Enums/InfinityEnums'

const FirmwareModal = React.forwardRef(
  (
    {
      open,
      currentFirmware,
      updateChargePointFirmware,
      updateChargePointFirmwareRequestState,
      chargePoint,
      uploadProgress,
      updateChargePointFirmwareUpload,
      tenantUser,
      uploadChargePointFirmware,
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const [uploadedFile, setUploadedFile] = useState(null)
    const [firmwareURL, setFirmwareURL] = useState('')
    const [firmwareURLError, setFirmwareURLError] = useState('')
    const [canSubmit, setCanSubmit] = useState(false)
    const [typedText, setTypedText] = useState(false)
    const [clickedUpload, setClickedUpload] = useState(false)

    const handleSubmit = async () => {
      setCanSubmit(false)
      if (typedText) {
        updateChargePointFirmware(firmwareURL, chargePoint._id)
      } else if (uploadedFile) {
        setClickedUpload(true)
        await uploadChargePointFirmware(
          uploadedFile[0],
          (progress) => updateChargePointFirmwareUpload(progress),
          () => {
            updateChargePointFirmware(uploadedFile[0].name, chargePoint._id)
          },
        )
      }
      setCanSubmit(false)
    }

    const handleModalClose = () => {
      setUploadedFile(null)
      setFirmwareURL('')
      setFirmwareURLError('')
      setCanSubmit(false)
      setTypedText(false)
      updateChargePointFirmwareUpload(0)
      setClickedUpload(false)
    }

    useEffect(() => {
      if (updateChargePointFirmwareRequestState === RequestState.SUCCEEDED) {
        ref.current.handleClose()
        updateChargePointFirmwareUpload(0)
      } else if (
        updateChargePointFirmwareRequestState !== RequestState.UNINITIALIZED &&
        updateChargePointFirmwareRequestState !== RequestState.LOADING
      ) {
        updateChargePointFirmwareUpload(0)
        setCanSubmit(true)
        setClickedUpload(false)
      }
    }, [updateChargePointFirmwareRequestState])

    return (
      <IFModal
        ref={ref}
        title={t('FirmwareModal.Title')}
        width={'40.5rem'}
        open={open}
        onClose={handleModalClose}
        modalCloseDisabled={
          updateChargePointFirmwareRequestState === RequestState.LOADING
        }
      >
        <div className={Styles.Container}>
          <IFText
            className={
              tenantUser.permissions.includes(
                InfinityEnums.TenantUserPermission.CAN_UPLOAD_FIRMWARE,
              )
                ? Styles.CurrentFirmware
                : ''
            }
            style={{ color: Colors.CurrentFirmwareText }}
          >
            {t('FirmwareModal.CurrentFirmwareText') + ': ' + currentFirmware}
          </IFText>
          {tenantUser.permissions.includes(
            InfinityEnums.TenantUserPermission.CAN_UPLOAD_FIRMWARE,
          ) ? (
            <div>
              <IFText
                style={{ color: Colors.UploadModalUpdateText }}
                className={Styles.UpdateText}
              >
                {t('FirmwareModal.UpdateText')}
              </IFText>
              <div className={uploadedFile ? Styles.FadeOut : Styles.FadeIn}>
                <div
                  className={
                    uploadedFile
                      ? Styles.InputContainerFade
                      : Styles.InputContainer
                  }
                >
                  <div className={Styles.URLTextContainer}>
                    <IFText
                      className={Styles.Label}
                      style={{ color: Colors.text }}
                    >
                      {t('FirmwareModal.firmwareURL')}
                    </IFText>
                  </div>
                  <IFTextInput
                    style={{ caretColor: Colors.primary }}
                    className={Styles.Input}
                    isFixed={true}
                    value={firmwareURL}
                    name="firmwareURL"
                    type="text"
                    onChange={(e) => {
                      setFirmwareURL(e.target.value)
                      if (e.target.value.length > 0) {
                        setTypedText(true)
                      } else setTypedText(false)
                      if (firmwareURLError.length > 0) {
                        e.target.value.length === 0
                          ? setFirmwareURLError(t('FirmwareModal.Required'))
                          : setFirmwareURLError('')
                      }
                      const submit = e.target.value.length > 0
                      setCanSubmit(submit)
                    }}
                    onBlur={(e) => {
                      e.target.value.length === 0
                        ? setFirmwareURLError(t('CardsPage.Required'))
                        : setFirmwareURLError('')
                      const submit = e.target.value.length > 0
                      setCanSubmit(submit)
                    }}
                    {...(firmwareURLError.length > 0
                      ? { errorText: firmwareURLError }
                      : {})}
                  />
                </div>
              </div>
              <div
                className={
                  typedText || uploadedFile
                    ? [Styles.DividerWrapperFade, Styles.FadeOut].join(' ')
                    : [Styles.DividerWrapper, Styles.FadeIn].join(' ')
                }
              >
                <div className={Styles.DividerContainer}>
                  <Divider />
                </div>
                <div className={Styles.DividerText}>
                  <IFText style={{ color: Colors.text }}>
                    {t('FirmwareModal.Or')}
                  </IFText>
                </div>
                <div className={Styles.DividerContainer}>
                  <Divider />
                </div>
              </div>

              <div className={typedText ? Styles.FadeOut : Styles.FadeIn}>
                <Dropzone
                  multiple={false}
                  onDrop={(acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                      setUploadedFile(acceptedFiles)
                      setCanSubmit(true)
                    }
                  }}
                  disabled={
                    updateChargePointFirmwareRequestState ===
                    RequestState.LOADING
                  }
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div
                          className={Styles.DropZone}
                          style={{ backgroundColor: Colors.DropZoneBackground }}
                        >
                          {uploadedFile == null ? (
                            <>
                              <FileUploadIcon />
                              <IFText>
                                {t('FirmwareModal.DragAndDropText')}
                              </IFText>
                            </>
                          ) : (
                            <>
                              <InsertDriveFileIcon fontSize="large" />
                              <div
                                className={Styles.CloseIconContainer}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  if (
                                    updateChargePointFirmwareRequestState !==
                                    RequestState.LOADING
                                  ) {
                                    setUploadedFile(null)
                                    setCanSubmit(false)
                                    updateChargePointFirmwareUpload(0)
                                  }
                                }}
                              >
                                <Icon
                                  className={
                                    updateChargePointFirmwareRequestState ===
                                    RequestState.LOADING
                                      ? Styles.CloseIconDisabled
                                      : Styles.CloseIcon
                                  }
                                  component={CancelIcon}
                                />
                              </div>
                              <IFText>{uploadedFile[0].name}</IFText>
                            </>
                          )}
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>

              <div className={Styles.BottomContainer}>
                <div className={Styles.UploadProgressContainer}>
                  {uploadedFile && clickedUpload ? (
                    <Progress.Line percent={uploadProgress} status="active" />
                  ) : null}
                </div>
                <IFButton
                  size="sm"
                  text={t('FirmwareModal.UpdateButtonText')}
                  color={Colors.primary}
                  isFill={true}
                  isDead={!canSubmit}
                  onClick={handleSubmit}
                  isLoading={
                    updateChargePointFirmwareRequestState ===
                      RequestState.LOADING || clickedUpload
                  }
                />
              </div>
            </div>
          ) : null}
        </div>
      </IFModal>
    )
  },
)

function mapDispatchToProps(dispatch) {
  return {
    updateChargePointFirmware: (file, chargePointId, onUploadProgress) =>
      dispatch(
        ChargePointActions.updateChargePointFirmware(
          file,
          chargePointId,
          onUploadProgress,
        ),
      ),
    updateChargePointFirmwareUpload: (progress) =>
      dispatch(ChargePointActions.updateChargePointFirmwareUpload(progress)),
    uploadChargePointFirmware: (file, onUploadProgress, onUploadDone) =>
      dispatch(
        ChargePointActions.uploadChargePointFirmware(
          file,
          onUploadProgress,
          onUploadDone,
        ),
      ),
  }
}

const mapStateToProps = (state) => ({
  updateChargePointFirmwareRequestState:
    ChargePointSelectors.getUpdateChargePointFirmwareRequestState(state),
  chargePoint: ChargePointSelectors.getSelectedChargePoint(state),
  uploadProgress:
    ChargePointSelectors.getChargePointFirmwareUploadProgress(state),
  tenantUser: AuthSelectors.getTenantUser(state),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(FirmwareModal)
