const en = {
  SideNavBar: {
    logout: 'logout',
  },
  StationListHeader: {
    Sites: 'Sites',
    Site: 'Site',
  },
  StationList: {
    Empty: 'No sites here',
  },
  StationListItem: {
    New: 'New',
  },
  IFRangePicker: {
    Min: 'Min',
    Max: 'Max',
    Ok: 'OK',
  },
  IFFilter: {
    ClearButton: 'Clear',
    or: ' or ',
    Ascending: 'Ascending',
    Descending: 'Descending',
    Download: 'Download',
  },
  IFDialog: {
    Cancel: 'Cancel',
  },
  ChargePointLogListItem: {
    callID: 'Call ID',
  },
  AddChargePointModal: {
    Title: 'Add Station',
    StationIDText: 'Station ID',
    StationNameText: 'Station Name',
    ButtonText: 'Done',
  },
  ConfigurationListItem: {
    type: 'Type',
    description: 'Description',
    done: 'Done',
    cancel: 'Cancel',
    Enabled: 'Enabled',
    Disabled: 'Disabled',
  },
  ConfigurationList: {
    EditSuccess: 'Configuration edited successfully',
    EditFail: 'Failed to edit configuration',
    RebootRequired: 'Station reboot required',
  },
  ConfigurationModal: {
    title: 'Configuration',
    lastUpdated: 'last updated',
    notAvailable: 'Not available',
  },
  MoveStationModal: {
    Title: 'Move Station',
    AcceptButtonText: 'Move',
  },
  ConnectorListItem: {
    kW: 'kW',
    Actions: 'Actions',
    StartTx: 'Start Tx',
    StopTx: 'Stop Tx',
    Unlock: 'Unlock',
    RefreshStatus: 'Request status update',
    ReserveConnector: 'Reserve',
    CancelConnectorReservation: 'Cancel reservation',
  },
  ConnectorList: {
    Empty: 'No connectors found',
  },
  FirmwareModal: {
    Title: 'Firmware',
    UpdateText: 'Update Firmware',
    DragAndDropText: 'Drag & Drop firmware file here',
    UpdateButtonText: 'Update',
    CurrentFirmwareText: 'Current Firmware',
    firmwareURL: 'URL ',
    Required: 'Required*',
    Or: 'Or',
  },
  AddStationModal: {
    Add: 'Add',
    AddSite: 'Add Site',
    SiteName: 'Site Name',
    StreetName: 'Street Name',
    City: 'City',
    Coordinates: 'Coordinates',
    Access: 'Access',
    Public: 'Public',
    Private: 'Private',
  },
  StationDetailsModal: {
    Done: 'Done',
    Cancel: 'Cancel',
    Edit: 'Edit',
    StreetName: 'Street Name',
    City: 'City',
    Coordinates: 'Coordinates',
    Delete: 'Delete',
    DeleteDialogTitle: 'Delete Site',
    DeleteDialogSubtitle:
      'Please type the name of the site to confirm deletion',
    DeleteButtonText: 'Delete',
    Public: 'Public',
    Private: 'Private',
    Access: 'Access',
  },
  Login: {
    Welcome: 'Welcome Back!',
    EmailAddress: 'Email Address',
    Password: 'Password',
    EmailAddressPlaceholder: 'example@example.com',
    PasswordPlaceholder: 'Password',
    SignIn: 'Sign In',
    ForgotPassword: 'Forgot password?',
    PasswordError: 'Password must be at least 8 characters',
    EmailError: 'Please enter a valid email',
    Required: 'Required*',
  },
  ChargePointDetails: {
    Connectors: 'Connectors',
    ConfigurationButtonText: 'Configuration',
    ChargingProfileButtonText: 'Charging Profile',
    DiagnosticsButtonText: 'Diagnostics',
    FirmwareButtonText: 'Firmware',
    MoveButtonText: 'Move',
    DeleteButtonText: 'Delete',
    CancelButtonText: 'Cancel',
    DoneButtonText: 'Done',
    HardResetButtonText: 'Hard',
    SoftResetButtonText: 'Soft',
    ResetButtonText: 'Reset',
    EditButtonText: 'Edit',
    FilterLogsBy: 'Filter Logs By',
    DeleteDialogTitle: 'Delete Station',
    DeleteDialogSubtitle:
      'Please type the ID of the station to confirm deletion',
    ResetDialogTitle: 'Reset Station',
    HardResetDialogSubtitle:
      'Are you sure you want to reset the station? Any running charging sessions will be stopped.',
    SoftResetDialogSubtitle: 'Are you sure you want to reset the station?',
    BothResetDialogSubtitle:
      'Are you sure you want to reset the station? In case of a hard reset, any running charging sessions will be stopped.',
    SerialNumber: 'Serial number',
    Model: 'Model',
    Vendor: 'Vendor',
    FirmwareVersion: 'Firmware version',
    OCPPVersion: 'OCPP version',
    IMSI: 'IMSI',
    ICCID: 'ICCID',
    ActivateButtonText: 'Activate',
    lastUpdated: 'last updated',
    ClearCache: 'Clear Cache',
    NotvisibleInApp: 'Not visible in App',
    VisibleInApp: 'Visible in App',
    Terminal: 'Terminal',
    MeterValue: 'Meter value',
    ErrorCode: 'Error code',
    VendorErrorCode: 'Vendor error code',
    VendorId: ' Vendor ID',
    ChargePointUptimeFilter: 'Select date range',
    ConnectorsUtilizationFilter: 'Select date range',
    NoResultsFound: 'No results found',
    AlertButtonText: 'Alerts',
  },
  ResetPassword: {
    Header: 'Reset Password',
    Email: 'Email Address',
    EmailAddressPlaceholder: 'example@example.com',
    EmailError: 'Please enter a valid email',
    Password: 'Password',
    ConfirmPassword: 'Confirm Password',
    Reset: 'Reset',
    PasswordMismatch: 'Passwords do not match',
    PasswordError: 'Password must be at least 8 characters',
    ModalTitle: 'Confirm Password Reset',
    ModalSubTitle: 'Please enter the verification code sent to',
    PinValidationString: '0123456789',
    ModalButtonText: 'Confirm',
    ResetPasswordSuccess: 'Password was reset successfully',
    Required: 'Required*',
  },
  StationsPage: {
    stationsTextFieldPlaceholder: 'Filter sites by',
  },
  ChargePointTab: {
    Inactive: 'Inactive',
    Connecting: 'Connecting',
  },
  emptyStates: {
    addStation: 'Add Station',
    addSite: 'Add Site',
    addCard: 'Add Card',
    noSiteshere: 'No Sites here',
    noCardsHere: 'No Cards here',
    noUsersHere: 'No Users here',
    stationEmptySites: 'Looks like you have no Sites',
    UsersEmpty: 'Looks like you have no Users',
    InviteUsers: 'Invite Users',
    CardsEmpty: 'Looks like you have no Cards',
    noStationsSite: 'Looks like you have no Stations in',
    thisSite: 'this Site',
    dashboard: `Start building your dashboard by adding a new`,
    newSite: 'site',
    newUser: 'user',
    newChargingToken: 'charging token',
    filterNoSites: 'Filter does not match any sites, try a new search',
    filterNoCards: 'Filter does not match any cards, try a new search',
    filterNoLogs: 'Filter does not match any logs, try a new search',
    query: 'query',
    hi: 'Hi ',
    NoInternet: 'No Internet connection found. Please check your',
    NoConnection: 'No Connection',
    ConnectionTry: 'connection and try again',
    TryAgain: 'Try again',
    NoLogsHere: 'No logs here',
    NoConfigs: 'Configuration not yet fetched',
    NoDiagnostics: 'No diagnostics here',
    TryToRefresh: 'We cannot find anything here, try refreshing the list',
    NoUserSessions: 'No ongoing sessions',
  },
  TenantUserListItem: {
    cancelButton: 'Cancel',
    updateButton: 'Update',
    roleSelectorHeader: 'Roles',
    NoPermissionsSelected: 'No permissions selected',
  },
  TenantUserList: {
    empty: 'No tenant users found',
    deleteModalCancel: 'Cancel',
    deleteModalConfirm: 'Delete',
    deleteModalTitle: 'Confirm Delete',
    deleteModalText: 'Are you sure you want to delete this tenant user?',
  },
  TenantUserPageContainer: {
    InviteTenantUser: 'Invite Tenant User',
    Name: 'Name',
    Email: 'Email Address',
    Role: 'Role',
    Invite: 'Invite',
    TenantUsers: 'Tenant Users',
    Permissions: 'Permissions',
    Required: 'Required*',
    Submit: 'Submit',
    EmailError: 'Must be a valid email',
    EmailAddressPlaceholder: 'example@example.com',
    Select: 'Select',
  },
  CreateAccountPage: {
    CreateAccount: 'Create An Account!',
    EmailAddress: 'Email Address',
    Password: 'Password',
    ConfirmPassword: 'Confirm Password',
    EmailAddressPlaceholder: 'example@example.com',
    PasswordPlaceholder: 'Password',
    ConfirmPasswordPlaceholder: 'Confirm Password',
    Register: 'Register',
    PasswordError: 'Password must be at least 8 characters',
    EmailError: 'Please enter A valid email',
    ConfirmPasswordError: 'Passwords must match',
    Required: 'Required*',
    Name: 'Name',
    NameError: 'Name must only contain letters',
    AcceptedInvite: 'You have already accepted an invitation.',
    ToLogin: 'To login, please click ',
    Here: 'here.',
  },
  InviteTenantUserListItem: {
    Delete: 'Delete',
    Resend: 'Resend',
    InvitationSent: 'Invitation sent',
  },
  StartTxDialogue: {
    TokenTitle: 'Enter Charging Token',
    EmailTitle: 'Enter User Email',
    Start: 'Start',
    EgToken: 'E.g. 04B0267AE05C39',
    EmailPhonePlaceHolder: 'Enter user email or phone',
    TokenCanNotBeEmpty: 'Token cannot be empty',
    EmailCanNotBeEmpty: 'Email cannot be empty',
    EnterValidEMailPhone: 'Enter Valid Email or Phone',
  },
  ReserveDialogue: {
    Reserve: 'Reserve',
    CanNotBeEmpty: 'Token cannot be empty',
    TokenTitle: 'Enter Charging Token',
    EmailTitle: 'Enter User Email',
    EgToken: 'E.g. 04B0267AE05C39',
    EgEmail: 'user@example.com',
    TokenCanNotBeEmpty: 'Token cannot be empty',
    EmailCanNotBeEmpty: 'Email cannot be empty',
    EnterValidEMail: 'Enter Valid Email',
    ReserveFor: 'Reserve for',
    FieldEmail: 'Email',
    FieldToken: 'Charging token uid',
  },
  ToasterMessages: {
    InvitationSuccess: 'Invitation sent successfully',
    CardUpdated: 'Card updated successfully',
    StationUpdated: 'Station updated',
    StationActivated: 'Station activated',
  },
  CardListItem: {
    Done: 'Done',
    Cancel: 'Cancel',
  },
  CardList: {
    deleteModalConfirm: 'Delete',
    deleteModalTitle: 'Confirm Delete',
    deleteModalText: 'Are you sure you want to delete this card?',
  },
  CardsPage: {
    FilterPlaceholder: 'Filter Cards by',
    ContractID: 'Contract ID',
    Reference: 'Reference',
    RFID: 'RFID',
    Type: 'Type',
    Status: 'Status',
    AddButton: 'Add',
    AddNewCard: 'Add Card(s)',
    Required: 'Required*',
    Submit: 'Submit',
    Or: 'Or',
    DragAndDrop: 'Drag and drop excel/csv file here',
    Disabled: 'Disabled',
    ErrorHasOccurred: 'An error has occurred',
    ChargingTokens: 'Charging Tokens',
  },
  OngoingSession: {
    Ongoing: 'Ongoing',
    Reserved: 'Reserved',
    Remaining: 'remaining',
  },
  AddCityModal: {
    Title: 'Add City',
    Subtitle: 'Please type the name of the city',
    AddButtonText: 'Add',
  },
  TransactionsReport: {
    PDF: 'Download as PDF',
    Excel: 'Download as Excel',
  },
  StationsMap: {
    StationsView: 'Stations',
    HeatMapView: 'Heatmap',
    StationsFilterPlaceholder: 'Choose heatmap options',
    HeatMapFilterPlaceholder: 'Filter sites by',
    Last7Days: 'Last 7 days',
    Last30Days: 'Last 30 days',
    AllTime: 'All time',
  },
  NotFound: {
    404: '404',
    NotFound: 'Not Found',
    RequestNotFound: " We're sorry, the page you requested could not be found",
    PleaseGoBack: 'Please go back to the home page',
    GoHome: 'GO HOME',
  },
  OCPPService: {
    TerminalSuccessMessage: 'Request Sent',
    ConnectorReserved: 'Connector Reserved',
    ReservationCancelled: 'Reservation Cancelled',
    SessionStarted: 'Session Started Successfully',
    SessionStopped: 'Session Stopped Successfully',
    ConnectorUnlocked: 'Connector Unlocked Successfully',
    HardResetSuccessful: 'Hard Reset Successful',
    SoftResetSuccessful: 'Soft Reset Successful',
    CacheCleared: 'Cache cleared successfully',
    DiagnosticsStatusNotification: 'Diagnostics refresh sent',
    ScheduledChangeAvailability:
      'Station scheduled change availability for connector(s) ',
    StatusUpdateRequested: 'Status Update Requested',
  },

  CancelConnectorReservation: {
    Title: 'Cancel Reservation',
    Subtitle:
      'Are you sure you want to cancel the reservation on this connector?',
    ButtonText: 'Confirm',
  },
  UploadDiagnosticsModal: {
    Refresh: 'Refresh',
    Diagnostics: 'Diagnostics',
    RequestDiagnostics: 'Request Diagnostics',
    lastUpdated: 'last updated',
  },
  ChargePointSaga: {
    RequestChargePointDiagnostics: 'Get diagnostics request sent',
    UpdateChargePointFirmware: 'Firmware update request sent',
  },
  TerminalModal: {
    Title: 'Terminal',
    ButtonText: 'Send',
    InvalidJson: 'Invalid json',
    Response: 'Response',
    Payload: 'Payload',
    Type: 'Type',
    NotAllowed: 'Required or Optional field values are not allowed',
  },
  ChargePointPrivacyToggle: {
    Public: 'Public',
    Private: 'Private',
  },
  ChargePointLogsToggle: {
    Live: 'Live',
  },
  SubscriptionListItem: {
    ExpiryDate: 'Expires',
    ConsumedEnergy: 'Consumed Energy',
    Default: 'Default',
    Midnight: '12:00 am',
    CurrentTariff: 'Current Tariff',
    Tariffs: 'Tariffs',
    PointsCode: 'PTS',
  },
  SubscriptionList: {
    Name: 'Name',
    AC: 'AC',
    DC: 'DC',
  },
  ScheduleList: {
    Rule: 'Rule',
  },
  AccessGroupList: {
    AccessGroupName: 'Name',
    NoOfUsers: 'No. of Users',
    NoOfTokens: 'No. of Tokens',
  },
  ChargepointSpecs: {
    NoAccessGroups: 'No access groups here',
    NoSubscriptions: 'No subscriptions here',
    NoPrivacyRules: 'No schedule here',
    PublicStationsCaution: 'Not applied to public stations',
  },
  UserTransaction: {
    ConnectorType: 'Connector Type',
    ConnectorID: 'Connector ID',
    ReservationID: 'Reservation ID',
    Subscription: 'Subscription',
    ChargingLevel: 'Charging Level',
    TariffText: 'Tariff',
    Start: 'Start',
    End: 'End',
    Duration: 'Duration',
    TokenType: 'Token Type',
  },
  ChargePointSpecsEdit: {
    Subscriptions: 'Subscriptions',
    AccessGroups: 'Access Groups',
    Schedule: 'Schedule',
  },
  UsersPage: {
    InviteNewUser: 'Invite User(s)',
    Email: 'Email',
    Required: 'Required*',
    Submit: 'Submit',
    Or: 'Or',
    DragAndDrop: 'Drag and drop excel/csv file here',
    FileNotSupported: 'File format not supported',
    Next: 'Next',
    UploadedFile: 'Uploaded file',
    InvitationSuccess: 'Invitation has been sent successfully',
    InvitationsSuccess: 'Invitations have been sent successfully',
    Subscriptions: 'Subscriptions',
    AccessGroups: 'Access Groups',
    TransactionLog: 'Transaction Log',
    FilterLogsBy: 'Filter Logs By',
    DeleteUser: 'Delete User',
    DeleteUserSubtitle: "Please type the user's email to confirm deletion",
    DeleteButtonText: 'Delete',
    UsersPageTextFieldPlaceholder: 'Filter users by',
    Users: 'Users',
    Active: 'Active',
    Blocked: 'Blocked',
    Pending: 'Pending',
    UserUpdated: 'User Updated',
    OngoingSessions: 'Ongoing Sessions',
  },
  UserInfo: {
    Name: 'Name',
    Email: 'Email',
    ID: 'ID',
  },
  AddChargingTokensModal: {
    AddNewCard: 'Add Charging Token(s)',
    Next: 'Next',
    Required: 'Required*',
    Submit: 'Submit',
    Or: 'Or',
    DragAndDrop: 'Drag and drop excel/csv file here',
    FileNotSupported: 'File format not supported',
    UploadedFile: 'Uploaded file',
    ContractID: 'Contract ID',
    Reference: 'Reference',
    RFID: 'RFID',
    Type: 'Type',
    Status: 'Status',
  },
  CardDetails: {
    Subscriptions: 'Subscriptions',
    AccessGroups: 'Access Groups',
    TransactionLog: 'Transaction Log',
    FilterLogsBy: 'Filter Logs By',
    DeleteCard: 'Delete Card',
    DeleteCardSubtitle: 'Please type the Card uid to confirm deletion',
    DeleteButtonText: 'Delete',
    ChargingTokenUpdated: 'Charging Token Updated',
  },
  StationUptimeMonitor: {
    ResetButtonText: 'Reset Graph',
    Title: 'Uptime Monitor',
    NoData: 'No data to show',
  },
  ConnectorUtilization: {
    ResetButtonText: 'Reset Graph',
    Title: 'Connectors Utilization',
    NoData: 'No data to show',
  },
  StationUptimePieChart: {
    UptimeStatus: {
      Online: 'Online',
      Offline: 'Offline',
      Unstable: 'Unstable',
    },
  },
  RequestDiagnosticsModal: {
    RequestDiagnosticsTitle: 'Request Diagnostics',
    SendRequest: 'Send Request',
    Date: 'Date (Optional)',
  },
  AlertModal: {
    Title: 'Alerts',
    Edit: 'Edit',
    Cancel: 'Cancel',
    Done: 'Done',
    NoAlertsAvailable: 'No Alerts Available',
  },
  LocalListModal: {
    CurrentVersion: 'Current Version',
    LocalAuthorizationList: 'Local Authorization List',
    LocalList: 'Local List',
    UpdateType: 'Update Type',
    AddLocalList: 'Add Local List',
    SendLocalList: 'Send Local List',
    LocalListEmpty: 'Local List Empty',
    ExpiryDate: 'Expiry Date',
    LocalListUpdated: 'Local List Updated',
  },
  ChargingProfile: {
    MaxCurrent: 'Max Current',
    ClearProfile: 'Remove Profile',
    RemoveChargingProfileTitle: 'Remove Charging Profile',
    RemoveChargingProfileSubtitle:
      'Are you sure you want to remove this charging profile?',
    ActionButton: 'Remove',
  },
  ChargingSchedulePeriod: {
    From: 'From:',
    To: 'To',
    A: 'A',
    MaxCurrent: 'Max Current:',
    OnePhase: '1-phase:',
    ThreePhase: '3-phase:',
  },
  ChargingScheduleModal: {
    Title: 'Set Charging Profile',
    NamePlaceHolder: 'Profile Name',
    Name: 'Name',
    MaxCurrent: 'Max Current',
    Send: 'Send',
  },
  ChargingProfileModal: {
    Title: 'Charging Profile Details',
    NoCompositeSchedule: 'No Composite Schedule',
    CreateProfile: 'Create Profile',
    CompositeSchedule: 'Composite Schedule',
  },
}

export default en
